'use client';

import { useTranslations } from 'next-intl';
import { Children, ReactNode } from 'react';

import { Container } from '@/components/container';
import { QuickLinksConfig } from '@/config-schema/home-page';
import { cn } from '@/utils/tailwind';

interface QuickLinksProps {
  config: QuickLinksConfig['config'];
  children: ReactNode;
  count: number;
}

const quantity = 8;
export default function QuickLinks({
  config,
  children,
  count,
}: QuickLinksProps) {
  const t = useTranslations('homePageQuickLinks');

  // setting backgroundColor to none until it's configurable in AP
  const backgroundColor = 'none';

  const startRedeemingNowText = t('startRedeemingNow');

  return (
    <Container style={{ backgroundColor }}>
      <h2 className="font-heading text-2xl-bold md:text-3xl-bold">
        {config.title || startRedeemingNowText}
      </h2>
      <div
        className={cn('mt-6 lg:mt-8', 'grid grid-cols-1 gap-4', 'lg:gap-8', {
          'lg:grid-cols-[repeat(auto-fit,_minmax(264px,_1fr))]': count > 6,
          'lg:grid-cols-[repeat(auto-fit,_minmax(176px,_max-content))]':
            count <= 6,
          'lg:grid-cols-[repeat(auto-fit,_minmax(176px,_1fr))]': count === 4,
        })}
        data-testid="quick-links"
      >
        {Children.map(children, (child, index) => {
          if (index < quantity) {
            return child;
          }
        })}
      </div>
    </Container>
  );
}
