'use client';

import { useIsTenantHub } from '@/hooks/config/tenants/use-is-tenanthub';
import { useTenantHubConfig } from '@/hooks/config/tenants/use-tenanthub-config';
import { useBootstrapData } from '@/store/store';
import { capitalizeFirstLetter } from '@/utils/text-utils';
import PageTitleProvider from './page-title-provider';

interface TenantHubPageTitleProviderProps {
  page: string;
}

export default function TenantHubPageTitleProvider({
  page,
}: TenantHubPageTitleProviderProps) {
  const isTenantHubTenant = useIsTenantHub();
  const tenantHubConfig = useTenantHubConfig();
  const bootstrapData = useBootstrapData();

  const tenantName =
    tenantHubConfig?.company_name ??
    bootstrapData?.uiSettings?.application?.tenantName;
  const displayTenantName = capitalizeFirstLetter(tenantName!);

  return isTenantHubTenant ? (
    <PageTitleProvider pageTitle={`${page} | ${displayTenantName}`} />
  ) : null;
}
