import { useHermes } from '@/auth/use-hermes';
import {
  Offer,
  OffersResponse,
  offerResponseSchema,
} from '@/features/offer/offer.schema';
import { dedupeOfferLocations } from '@/features/offer/utils/dedupe-offer-locations';
import { useAuthFetchKy } from '@/hooks/fetch/use-auth-fetch-ky';
import { queryKeys } from '@/queries/query-factory';
import { validateSchema } from '@/schema/validate-schema';
import { getClientUrl } from '@/utils/get-client-url';
import { useQuery, useQueryClient } from '@tanstack/react-query';

const responseFormatHeaders = new Headers({ 'x-response-format': 'custom' });

type UseOfferByIdParams = {
  id: string;
};

export function useOfferById({ id }: UseOfferByIdParams) {
  const authFetch = useAuthFetchKy;
  const baseUrl = getClientUrl();
  const queryClient = useQueryClient();
  const hermes = useHermes();

  const { isLoading, isError, data, error, isFetched } = useQuery({
    ...queryKeys.offer.detail(id),
    queryFn: async ({ signal }): Promise<Offer | null> => {
      const realOffersFetchPath = `${baseUrl}/api/offers/${id}/show_by_user`;

      const data = await authFetch
        .get(realOffersFetchPath, { headers: responseFormatHeaders, signal })
        .json();

      const response = validateSchema({
        data: data,
        schemaName: 'offers-schema',
        zodSchema: offerResponseSchema,
      });

      return { ...response, locations: dedupeOfferLocations(response) };
    },
    placeholderData: () => {
      // Most of the time, user goes from listing page -> details page to browse an offer.
      // Loading an offer by ID takes a few seconds since we are hitting Triple.
      // We will have placeholderData from the offer listing page results while we are hitting show_by_user
      // in the background.
      const useOffersQueryData = queryClient.getQueriesData<OffersResponse>([
        'offer',
        'list',
      ]);

      if (!useOffersQueryData) {
        return null;
      }

      const allPossibleOffers = useOffersQueryData.flatMap(
        (offerQueryResult) => {
          const [_queryKey, offerQueryResponse] = offerQueryResult;

          if (!offerQueryResponse) {
            return [];
          }

          return offerQueryResponse.data;
        },
      );

      return allPossibleOffers.find((offer) => offer.id === id);
    },
    staleTime: 1000 * 60 * 5,
    enabled: hermes.loggedIn,
  });

  return {
    isLoading,
    isError,
    error,
    isFetched,
    offer: data,
  };
}
