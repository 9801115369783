export function optimizeImage(
  imageUrl: string,
  width?: number,
  quality?: number,
) {
  if (imageUrl.startsWith('https://offershub-cdn.kaligo-staging.xyz/images/')) {
    const cloudflareImage = imageUrl.replace(
      'https://offershub-cdn.kaligo-staging.xyz/images/',
      'https://offershub-cdn.kaligo-staging.xyz/images-proxy/',
    );
    return `${cloudflareImage}?format=webp${width ? `&width=${width}` : ''}${
      quality ? `&quality=${quality}` : ''
    }`;
  }

  return `${imageUrl}?format=webp${width ? `&width=${width}` : ''}${
    quality ? `&quality=${quality}` : ''
  }`;
}
