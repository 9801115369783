import { useTranslations } from 'next-intl';
import { Button, DialogTrigger } from 'react-aria-components';

import { Icon } from '@/components/icon';
import { MenuItemType } from '@/config-schema/navigation';
import Drawer, {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderCloseButton,
} from '@/design-system-components/drawer/drawer';
import { cn } from '@/utils/tailwind';
import { QuickLinkItem } from './quick-link-item';

const NO_RECOMMENDED_LINKS = 3;

export function MobileQuickLink({
  className,
  headerLinks,
}: {
  headerLinks: MenuItemType[];
  className?: string;
}) {
  const count = headerLinks.length;

  if (count <= 1) {
    return null;
  }

  const showMoreButton = count >= NO_RECOMMENDED_LINKS + 2;
  const twoItems = count === 2;
  const threeItems = count === 3;
  const wrapperClassName = threeItems
    ? 'grid grid-cols-3 gap-[18px]'
    : twoItems
    ? 'grid grid-cols-2 gap-[18px]'
    : 'grid grid-cols-4 gap-[18px]';

  return (
    <div className={cn(wrapperClassName, className, 'container-responsive')}>
      {headerLinks
        .slice(0, showMoreButton ? NO_RECOMMENDED_LINKS : count)
        .map((menuItem) => (
          <QuickLinkItem
            key={menuItem.itemType}
            menuItem={menuItem}
            className={twoItems ? 'flex-row' : 'flex-col'}
            titleClassName="line-clamp-1 text-sm font-normal !text-neutral-900"
          />
        ))}
      {showMoreButton ? <MoreButton menuItems={headerLinks} /> : null}
    </div>
  );
}

export function MoreButton({
  menuItems,
  className,
}: {
  menuItems: MenuItemType[];
  className?: string;
}) {
  const t = useTranslations('homePageMobileQuickLink');

  const moreText = t('more');
  const recommendedForYouText = t('recommendedForYou');
  const otherRewardsText = t('otherRewards');
  return (
    <DialogTrigger>
      <Button
        className={cn(
          'flex flex-col items-center justify-center gap-2',
          'gtm:homepage_view_more',
          className,
        )}
      >
        <div className="flex h-10 w-10 rounded-full bg-cloudburst-150 p-3 text-primary">
          <Icon name="shapes" className="h-5 w-5" />
        </div>
        <p className="text-neutral-900">{moreText}</p>
      </Button>
      <Drawer>
        {(close) => {
          return (
            <>
              <DrawerHeader className="flex-row justify-end">
                <DrawerHeaderCloseButton
                  drawerVariant="modal"
                  className="text-primary-700"
                  onCloseHandler={() => close.close()}
                />
              </DrawerHeader>
              <DrawerBody className="pb-6">
                <p className="pb-4 font-heading text-lg-bold md:text-xl-bold">
                  {recommendedForYouText}
                </p>
                <div className="grid grid-cols-4 gap-[18px]">
                  {menuItems.slice(0, NO_RECOMMENDED_LINKS).map((menuItem) => (
                    <QuickLinkItem
                      className="flex-col"
                      menuItem={menuItem}
                      key={menuItem.itemType}
                      titleClassName="line-clamp-1 text-sm font-normal !text-neutral-900"
                    />
                  ))}
                </div>

                <p className="pb-4 pt-8 font-heading text-lg-bold md:text-xl-bold">
                  {otherRewardsText}
                </p>
                <div className="grid grid-cols-4 gap-[18px]">
                  {menuItems.slice(NO_RECOMMENDED_LINKS).map((menuItem) => (
                    <QuickLinkItem
                      className="flex-col"
                      menuItem={menuItem}
                      key={menuItem.itemType}
                      titleClassName="line-clamp-1 text-sm font-normal !text-neutral-900"
                    />
                  ))}
                </div>
              </DrawerBody>
            </>
          );
        }}
      </Drawer>
    </DialogTrigger>
  );
}
