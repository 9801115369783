'use client';

import { useTranslations } from 'next-intl';
import { useContext } from 'react';

import { RcLink } from '@/components/rc-link';
import { ProductMetaData, QuickLinkGtmProps } from '@/config-schema/home-page';
import { getDefaultOffersURLSearchParams } from '@/features/offer/offer-filters';
import { useFeatureConfig } from '@/hooks/config/use-feature-config';
import { useUberCredit } from '@/hooks/loyalty-programs/use-uber-credit';
import { RedirectModalContext } from '@/root-provider/redirect-modal-provider';
import { cn } from '@/utils/tailwind';

interface QuickLinkProps {
  id: string;
  productType: ProductMetaData['productType'];
  type: ProductMetaData['type'];
  tileBackgroundColor: string | null;
  image: ProductMetaData['image'] | null;
  openInNewTab?: boolean;
  url?: string;
  title: string;
}

export default function QuickLink(props: QuickLinkProps) {
  let ariaLabel = '';
  let productName = '';

  const t = useTranslations('homePageQuickLink');
  const { setShowRedirectModal } = useContext(RedirectModalContext);
  const uberEnabled = useFeatureConfig('uberCredit')?.enabled;
  const { uberLoyaltyProgramId } = useUberCredit({ enabled: !!uberEnabled });

  const redeemGiftCardsText = t('redeemGiftCards');
  const giftCardsText = t('giftCards');
  const redeemPointsTransferText = t('redeemPointsTransfer');
  const pointsTransferText = t('pointsTransfer');
  const redeemCarbonOffsetText = t('redeemCarbonOffset');
  const carbonOffsetText = t('carbonOffset');
  const redeemCashbackText = t('redeemCashback');
  const cashbackText = t('cashback');
  const flightText = t('flight');
  const hotelsText = t('hotels');
  const redeemOffersText = t('redeemOffers');
  const offerText = t('offer');
  const redeemCryptoText = t('redeemCrypto');
  const cryptoText = t('crypto');
  const redeemUberCreditText = t('redeemUberCredit');
  const uberCreditText = t('uberCredit');
  const redeemAppleMerchandiseText = t('redeemAppleMerchandise');
  const appleMerchandiseText = t('appleMerchandise');
  const urlText = t('url');

  let link = '';
  switch (props.productType) {
    case 'gift-card': {
      link =
        props.type === 'listing'
          ? '/products/gift-cards'
          : `/products/gift-cards/${props.id}`;
      ariaLabel = props.type === 'listing' ? redeemGiftCardsText : props.title;
      productName = props.title ?? giftCardsText;
      break;
    }
    case 'points-transfer': {
      link =
        props.type === 'listing'
          ? '/products/frequent-traveler-programs'
          : `/products/frequent-traveler-programs/${props.id}`;
      ariaLabel =
        props.type === 'listing' ? redeemPointsTransferText : props.title;
      productName = props.title ?? pointsTransferText;
      break;
    }
    case 'carbon': {
      link =
        props.type === 'listing'
          ? '/products/sustainability'
          : `/products/sustainability/${props.id}`;
      ariaLabel =
        props.type === 'listing' ? redeemCarbonOffsetText : props.title;
      productName = props.title ?? carbonOffsetText;
      break;
    }
    case 'cashback': {
      link =
        props.type === 'listing'
          ? '/products/cashback'
          : `/products/cashback/${props.id}`;
      link = `/products/cashback/${props.id ?? ''}`;
      ariaLabel = props.type === 'listing' ? redeemCashbackText : props.title;
      productName = props.title ?? cashbackText;
      break;
    }
    case 'travel': {
      link = 'travel/flights';
      ariaLabel = props.title;
      productName = props.title ?? flightText;
      break;
    }
    case 'hotel': {
      link = 'travel/hotels';
      ariaLabel = props.title;
      productName = props.title ?? hotelsText;
      break;
    }
    case 'offer': {
      link =
        props.type === 'listing'
          ? `/products/offers?${getDefaultOffersURLSearchParams()}`
          : `/products/offers/${props.id}`;
      ariaLabel = props.type === 'listing' ? redeemOffersText : props.title;
      productName = props.title ?? offerText;
      break;
    }
    case 'crypto': {
      link =
        props.type === 'listing'
          ? '/products/crypto'
          : `/products/crypto/${props.id}`;
      ariaLabel = props.type === 'listing' ? redeemCryptoText : props.title;
      productName = props.title ?? cryptoText;
      break;
    }
    case 'uber-credit': {
      link = `/products/uber-credit/${uberLoyaltyProgramId ?? ''}`;
      ariaLabel = redeemUberCreditText;
      productName = props.title ?? uberCreditText;
      break;
    }
    case 'apple-merchandise': {
      link = '/auth/apple';
      ariaLabel = redeemAppleMerchandiseText;
      productName = props.title ?? appleMerchandiseText;
      break;
    }
    case 'url': {
      link = props.url || '';
      ariaLabel = props.title;
      productName = props.title ?? urlText;
      break;
    }
    default: {
      break;
    }
  }

  // setting tileBackgroundColor to white until it's configurable in AP
  const tileBackgroundColor = '#FFFFFF';
  const dataGtm = {
    event: 'homepage_quick_link_click',
    group_0: props.productType,
    type_0: props.type,
  } satisfies QuickLinkGtmProps;

  const isGuardhouseLink = link.startsWith('/auth/');

  return (
    <RcLink
      href={link}
      className={cn(
        'gtm:homepage_quick_link',
        'rounded border border-transparent hover:border-black',
      )}
      aria-label={ariaLabel}
      target={props.openInNewTab ? '_blank' : '_self'}
      rel={props.openInNewTab ? 'noopener noreferrer' : undefined}
      data-gtm={JSON.stringify(dataGtm)}
      onClick={(e) => {
        if (isGuardhouseLink) {
          e.preventDefault();
          setShowRedirectModal(true);
        }
      }}
    >
      <div
        style={{
          backgroundColor: tileBackgroundColor,
        }}
        className="flex h-full w-full overflow-hidden rounded lg:flex-col"
      >
        <div className="w-[144px] lg:w-full lg:flex-1">
          {props.image ? (
            <img
              className="max-h-[280px] w-full max-w-[280px] object-contain"
              src={props.image.url}
              alt={props.image.alt ?? ''}
              loading="lazy"
            />
          ) : (
            // Workaround to not have errors for missing src
            // Can remove later if need
            <div className="m-auto my-3 h-[120px] w-[calc(100%_-_2rem)] bg-primary-200 lg:my-7 lg:h-[160px]" />
          )}
        </div>
        <div className="flex min-h-[56px] w-full items-center p-4 text-xs lg:mt-auto lg:flex-shrink-0 lg:justify-center lg:p-2 lg:text-center lg:text-lg">
          {/* Use body-color here as per https://kaligo.atlassian.net/browse/BUG-2892 */}
          <p className="text-body-color font-heading text-lg-bold md:text-xl-bold">
            {productName}
          </p>
        </div>
      </div>
    </RcLink>
  );
}
