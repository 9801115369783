import { z } from 'zod';
import { orderItemSchema, redeemAgainItemSchema } from './order/order-item';

export const orderItemsApiResponseSchema = z.object({
  data: z.array(orderItemSchema),
  meta: z.object({
    count: z.number(),
  }),
});

export const redeemAgainItemsApiResponseSchema = z.object({
  data: z.array(redeemAgainItemSchema),
  meta: z.object({
    count: z.number(),
  }),
});

export type RedeemAgainItemsType = z.infer<
  typeof redeemAgainItemsApiResponseSchema
>;
